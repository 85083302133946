import { LangContainer } from "./styles";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Langs: Record<string, string> = {
  ru: "Рус",
  kk: "Қаз",
};

const LangSwitcher = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const { i18n } = useTranslation();
  const handleToggle = () => {
    setMenuOpen((prevState) => !prevState);
  };
  return (
    <LangContainer>
      <ButtonDropdown isOpen={menuOpen} toggle={handleToggle}>
        <DropdownToggle tag={"a"} caret>
          {Langs[i18n.language]}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={() => {
              i18n.changeLanguage("ru");
            }}
          >
            Рус
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              i18n.changeLanguage("kk");
            }}
          >
            Қаз
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    </LangContainer>
  );
};

export default LangSwitcher;
