import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";
import { ListRequest } from "store/types";
import { journalApi } from "./api";
import { JournalWrapper, ResponseState } from "./types";

const defaultFilter: ListRequest = {
  filterId: nanoid(),
  filter: null,
  number: 0,
  size: 5,
  sortField: null,
  sort: null,
  from: null,
  to: null,
};

const initialState: ResponseState = {
  journals: {
    filter: defaultFilter,
    result: {
      data: [],
      pageNumber: 0,
      pageSize: 0,
      totalElements: 0,
      totalPages: 0,
    },
  },
};

export const journalSlice = createSlice({
  name: "journal",
  initialState,
  reducers: {
    changeFilter: (state, action: PayloadAction<ListRequest>) => {
      state.journals.filter = {
        ...action.payload,
        filterId: nanoid(),
      };
    },
    resetFilter: (state) => {
      state.journals.filter = defaultFilter;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      journalApi.endpoints.getJournals.matchFulfilled,
      (state, action: PayloadAction<JournalWrapper>) => {
        state.journals.result = action.payload;
      }
    );
  },
});

export const { changeFilter, resetFilter } = journalSlice.actions;

export default journalSlice.reducer;
