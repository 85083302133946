import React from "react";
import { Input, Label } from "reactstrap";
import { Wrapper } from "./styles";
import { Props } from "./types";

type TextInputProps = Props & React.InputHTMLAttributes<HTMLInputElement> & {
  innerRef?: React.Ref<HTMLInputElement | HTMLTextAreaElement>;
  bsSize?: 'lg' | 'sm';
  valid?: boolean;
  invalid?: boolean;
};

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  ({ label, name, ...rest }, ref) => {
    return (
      <Wrapper>
        {label && <Label for={name}>{label}</Label>}
        <Input {...rest} type="text" innerRef={ref} name={name} />
      </Wrapper>
    );
  }
);

export default TextInput;
