import React, { useState } from "react";
import NavLink from "./NavLink";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import {
  UserAvatar,
  UserAvatarLabel,
  UserContainer,
  UserInfo,
  UserName,
  UserRole,
} from "./styles";
import { useAppDispatch, useAppSelector } from "store";
import { logout } from "store/auth/reducer";
import { useNavigate } from "react-router-dom";
import { ChangePasswordModal } from "app/features/users/users-change-password";

const UserPanel = () => {
  const [changePasswordDialog, setChangePasswordDialog] =
  useState<boolean>(false);
  const { userInfo } = useAppSelector((state) => state.auth);
  const { userData } = useAppSelector((state) => state.user);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleToggle = () => {
    setMenuOpen((prevState) => !prevState);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const handleOpenDialog = () => {
    setChangePasswordDialog(true);
  };

  const handleCloseDialog = async () => {
    setChangePasswordDialog(false);
  };

  const handleSuccessDialog = async () => {
    handleCloseDialog();
    handleLogout();
  };

  return (
    <React.Fragment>
      <ButtonDropdown isOpen={menuOpen} toggle={handleToggle}>
        <DropdownToggle tag={"div"}>
          <UserContainer>
            <UserInfo>
              <UserName>{userData?.fio}</UserName>
              <UserRole>{userInfo?.mainRole}</UserRole>
            </UserInfo>
            <UserAvatar>
              <UserAvatarLabel>
                {userData?.fio?.substring(0, 1)}
              </UserAvatarLabel>
            </UserAvatar>
          </UserContainer>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem>
            <NavLink to={"/profile"}>Профиль</NavLink>
          </DropdownItem>
          <DropdownItem onClick={handleOpenDialog}>Сменить пароль</DropdownItem>
          <DropdownItem onClick={handleLogout}>Выйти</DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
      {changePasswordDialog && (
        <ChangePasswordModal
          onClose={handleCloseDialog}
          onOk={handleSuccessDialog}
          modalDialog={changePasswordDialog}
          modalTitle="Смена пароля"
        />
      )}
    </React.Fragment>
  );
};

export default UserPanel;
