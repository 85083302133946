import { Button } from "reactstrap";
import styled from "styled-components/macro";

export const ButtonContainer = styled(Button)`
  padding: 8px 22px;
  font-size: 14px;
  font-weight: 500;
  outline: none;

  &.btn-primary:not(:disabled) {
    background-color: #0094e0;
    border-color: transparent;
  }

  &.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: .875rem;
    border-radius: 0.2rem;
  }
`;
