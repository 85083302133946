import ReactDOM from "react-dom";
import App from "./app";

import { store } from "./store";
import { Provider } from "react-redux";

import './locales/i18n';

ReactDOM.render(
  <Provider store={store}>
   <App />
  </Provider>,
document.getElementById("root"));
