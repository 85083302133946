import styled from "styled-components/macro";

export const Wrapper = styled.div`
  margin: auto 0;
  display: flex;
`;

export const LangContainer = styled.div`
  padding: 10px 22px;
  color: #fff;
  cursor: pointer;

  a {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
  }
`;

export const LangSelected = styled.span`
  margin-right: 6px;
`;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
`;

export const UserInfo = styled.div`
  font-size: 14px;
`;

export const UserName = styled.div`
  color: #ffffff;
  opacity: 0.5;
`;

export const UserRole = styled.div`
  color: #ffffff;
  text-align: right;
`;

export const UserAvatar = styled.div`
  margin-left: 10px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  width: 36px;
  height: 36px;
`;

export const UserAvatarLabel = styled.div`
  text-transform: uppercase;
  color: #fff;
  padding: 6px;
  text-align: center;
`;
