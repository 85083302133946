import { createApi } from "@reduxjs/toolkit/query/react";
import { ListRequest, ListResponse } from "store/types";
import axiosBaseQuery from "utils/request";
import { JournalDTO } from "./types";

export const journalApi = createApi({
  reducerPath: "journalApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api",
  }),
  tagTypes: ["JournalDTO"],
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getJournals: builder.query<ListResponse<JournalDTO>, ListRequest>({
      query: (args) => ({
        url: "/discrepancies/log",
        method: "GET",
        params: args,
      }),
    }),
  }),
});

export const { useGetJournalsQuery } = journalApi;
