import { FC } from "react";
import { ButtonProps } from "reactstrap";
import { ButtonContainer } from "./styles";

type Props = {
  children?: React.ReactNode;
  size?: string;
  outline?: boolean;
  disabled?: boolean;
} & ButtonProps;

const Button: FC<Props> = ({ children, size, outline, disabled, ...rest }) => {
  return (
    <ButtonContainer
      size={size}
      outline={outline}
      disabled={disabled}
      {...rest}
    >
      {children}
    </ButtonContainer>
  );
};

Button.defaultProps = {
  color: "primary",
};

export default Button;
