import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import authReducer from "./auth/reducer";
import userReducer from "./users/reducer";
import trainReducer from "./train/reducer";
import wagonReducer from "./wagon/reducer";
import journalReducer from "./journal/reducer";
import biometryReducer from "./biometryTrain/reducer";
import biometryWagonReducer from "./biometryWagon/reducer";
import { trainApi } from "./train/api";
import { wagonApi } from "./wagon/api";
import { journalApi } from "./journal/api";
import { biometryTrainApi } from "./biometryTrain/api";
import { biometryWagonApi } from "./biometryWagon/api";
import { authApi } from "./auth/api";
import { userApi } from "./users/api";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    train: trainReducer,
    wagon: wagonReducer,
    journal: journalReducer,
    biometry: biometryReducer,
    biometryWagon: biometryWagonReducer,
    [authApi.reducerPath]: authApi.reducer,
    [trainApi.reducerPath]: trainApi.reducer,
    [wagonApi.reducerPath]: wagonApi.reducer,
    [journalApi.reducerPath]: journalApi.reducer,
    [biometryTrainApi.reducerPath]: biometryTrainApi.reducer,
    [biometryWagonApi.reducerPath]: biometryWagonApi.reducer,
    [userApi.reducerPath]: userApi.reducer
  },
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      authApi.middleware,
      trainApi.middleware,
      wagonApi.middleware,
      journalApi.middleware,
      biometryTrainApi.middleware,
      biometryWagonApi.middleware,
      userApi.middleware
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
