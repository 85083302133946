import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestState, UserResponse } from "./types";
import type { RootState } from "../../store";

const initialState: RequestState = {
  isLoading: false,
  error: "",
  success: false,
  userInfo: null,
  userToken: null
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.error = "";
      state.isLoading = false;
      state.success = false;
      state.userInfo = null;
      state.userToken = null;
      localStorage.clear();
    },
    checkUser: (state, action: PayloadAction<UserResponse>) => {
      if (action.payload) {
        state.userInfo = action.payload;
        state.userToken = action.payload.token;
        state.userInfo.mainRole = action.payload.roles[0];
        state.success = true;
      }
    },
    setUserToken: (state, action: PayloadAction<string | null>) => {
      state.userToken = action.payload;
    },
    login: (state, action: PayloadAction<UserResponse>) => {
      state.userInfo = {
        ...action.payload,
        mainRole: action.payload.roles[0],
      };
      state.userToken = action.payload?.token;
      state.success = true;
      localStorage.setItem("userInfo", JSON.stringify(action.payload));
      localStorage.setItem("accessToken", action.payload?.token);
    },
  },
});
export const { logout, checkUser, login, setUserToken } = authSlice.actions;

export const selectUserIsAdmin = (state: RootState) =>
  state.user.userData?.roles.some((role) => role.name === "ADMIN");

export const selectIsAuthorized = (state: RootState) => state.auth.success;

export const selectUserToken = (state: RootState) => state.auth.userToken;

export default authSlice.reducer;
