import styled from "styled-components/macro";

export const Wrapper = styled.div`
  margin: auto;
`;

export const Links = styled.div`
  display: flex;
  a {
    font-size: 14px;
    padding: 10px 28px;
    border-radius: 6px;
    color: #fff;
    text-decoration: none;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }

    &.active {
      background: rgba(255, 255, 255, 0.2);
    }
  }
`;
