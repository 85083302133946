import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { wagonApi } from "./api";
import { ResponseState, WagonWrapper } from "./types";

const initialState: ResponseState = {
  wagons: [],
};

export const wagonSlice = createSlice({
  name: "wagon",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      wagonApi.endpoints.getWagons.matchFulfilled,
      (state, action: PayloadAction<WagonWrapper>) => {
        state.wagons = action.payload?.data;
      }
    );
  },
});

export default wagonSlice.reducer;
