import styled from "styled-components/macro";

export const Wrapper = styled.div`
  .form-control {
    background-color: #eef1f5;
    border-color: transparent;
    border-radius: 8px;

    &:focus {
      box-shadow: 0 0 0 0.35px rgb(13 110 253 / 25%);
    }
  }

  .form-control::placeholder {
    color: hsl(0, 0%, 50%);
  }
`;

export const CheckBoxWrapper = styled.div`
  text-align: left;

  .form-check-label {
    color: #397ef3;
    font-size: 14px;
  }

  .form-check-input {
    background-color: #eef1f5;
    border-color: transparent;
  }
  .form-check-input:checked {
    background-color: #0d6efd !important;
  }
`;
