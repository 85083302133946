import { RouteObject } from "react-router-dom";

export const biometricRoutes: RouteObject[] = [
  {
    index: true,
    async lazy() {
      const { TrainsPage } = await import("./Trains");

      return {
        element: <TrainsPage />,
      };
    },
  },
  {
    path: "wagons/:trainId",
    async lazy() {
      const { WagonsPage } = await import("./Wagons");

      return {
        element: <WagonsPage />,
      };
    },
  },
  {
    path: "wagons/photos/:trainId/:wagonId",
    async lazy() {
      const { WagonPhotosPage } = await import("./WagonPhotos");

      return {
        element: <WagonPhotosPage />,
      };
    },
  },
];
