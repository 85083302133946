import { createApi } from "@reduxjs/toolkit/query/react";
import { ListRequest } from "store/types";
import axiosBaseQuery from "utils/request";
import { BioWagonDTO } from "./types";

export const biometryWagonApi = createApi({
  reducerPath: "biometryWagonApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api",
  }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getWagons: builder.query<
      BioWagonDTO[],
      ListRequest & {
        trainId: string;
      }
    >({
      query: ({ trainId, ...args }) => ({
        url: `/biometric-info/wagons/${trainId}`,
        method: "GET",
        params: args,
      }),
    }),
  }),
});

export const { useGetWagonsQuery } = biometryWagonApi;
