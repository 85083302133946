import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";
import { ListRequest } from "store/types";
import { trainApi } from "./api";
import { ResponseState, TrainDTO, TrainWrapper } from "./types";

const defaultTrainsFilter:ListRequest = {
  filterId: nanoid(),
  filter: null,
  number: 0,
  size: 15,
  sortField: null,
  sort: null,
  from: null,
  to: null,
};

const initialState: ResponseState = {
  trains: {
    filter: defaultTrainsFilter,
    result: {
      data: [],
      pageNumber: 0,
      pageSize: 0,
      totalElements: 0,
      totalPages: 0,
    },
  },
  trainOne: null,
};

export const trainSlice = createSlice({
  name: "train",
  initialState,
  reducers: {
    changeFilter: (state, action: PayloadAction<ListRequest>) => {
      state.trains.filter = action.payload;
    },
    resetFilter: (state) => {
      state.trains.filter = defaultTrainsFilter;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      trainApi.endpoints.getTrains.matchFulfilled,
      (state, action: PayloadAction<TrainWrapper>) => {
        state.trains.result = action.payload;
      }
    );

    builder.addMatcher(
      trainApi.endpoints.getTrainById.matchFulfilled,
      (state, action: PayloadAction<TrainDTO>) => {
        state.trainOne = action.payload;
      }
    );
  },
});

export const { changeFilter, resetFilter } = trainSlice.actions;

export default trainSlice.reducer;
