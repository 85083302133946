import { CSSProperties } from "react";
import styled from "styled-components"

const SvgBoxContainer = styled.svg({
    position: 'relative',
});

type SvgIconProps = {
    sx?: CSSProperties,
} & React.ComponentProps<typeof SvgBoxContainer>

export function SvgIcon({ sx = {}, ...props }: SvgIconProps) {
    return (
        <SvgBoxContainer style={{ ...sx }} {...props} />
    )
}