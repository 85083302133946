import CompanyLogo from "assets/images/mini_logo.png";
import { useNavigate } from "react-router-dom";
import { LogoWrapper as Wrapper } from "./styles";

const Logo = () => {
  const navigate = useNavigate();
  const handleMain = () => {
    navigate("/");
  };
  return (
    <Wrapper onClick={handleMain}>
      <img src={CompanyLogo} alt="company logo" />
    </Wrapper>
  );
};

export default Logo;
