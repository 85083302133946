import { FC } from "react";
import { Wrapper } from "./styles";

type Props = {
  children?: React.ReactNode;
};

const PageWrapper: FC<Props> = ({ children }) => {
  return (
    <>
      <Wrapper>{children}</Wrapper>
    </>
  );
};

export default PageWrapper;
