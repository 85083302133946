import { memo, useCallback, useState } from "react";
import { toast, ToastOptions } from "react-toastify";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { axiosInstance } from "utils/request";

interface Props {
    onClose: () => void;
    cameraLiveUrl?: string;
    cameraDownloadUrl?: string;
}

const toastifyConfig: ToastOptions = {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "light",
}

const ModalLive = (props: Props) => {
    const { onClose, cameraLiveUrl, cameraDownloadUrl } = props;
    const accessToken = localStorage.getItem("accessToken");
    const [downloadLoading, setDownloadLoading] = useState(false);

    const handleDownloadVideo = useCallback(async () => {
        if (!cameraDownloadUrl) return;

        try {
            setDownloadLoading(true);
            const fileData = await axiosInstance.get(cameraDownloadUrl, {
                timeout: 0,
                responseType: 'blob',
                // onDownloadProgress: progressEvent => {
                //     // const total = parseFloat(progressEvent.currentTarget.responseHeaders['Content-Length'])
                //     const current = progressEvent.currentTarget.response

                //     // let percentCompleted = Math.floor(current * 100)
                //     console.log('completed: ', current)
                // }
            });

            const url = window.URL.createObjectURL(fileData?.data);
            const link = document.createElement('a');
            link.href = url;
            link.download = "video_train.mp4";
            document.body.appendChild(link);
            link.click();
            link.remove();
            setDownloadLoading(false);
        }
        catch (err) {
            toast.error('Ошибка при скачивании видео', toastifyConfig);
        }

    }, [cameraDownloadUrl]);

    return (
        <Modal size={"xl"} centered fade={false} isOpen={true} toggle={onClose}>
            <ModalHeader toggle={onClose}>Просмотр видеозаписи</ModalHeader>
            <ModalBody>
                {cameraLiveUrl && (
                    <video style={{ "width": "100%" }} controls controlsList="nodownload" autoPlay={true}>
                        <source src={`${cameraLiveUrl}?bearerToken=${accessToken}`} type="video/mp4" />
                    </video>
                )}
            </ModalBody>
            <ModalFooter>
                {cameraDownloadUrl && (
                    <Button disabled={downloadLoading} color="success" onClick={handleDownloadVideo}>
                        {downloadLoading ? "Скачивание видео..." : "Скачать"}
                    </Button>
                )}
                <Button color="secondary" onClick={onClose}>
                    Закрыть
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default memo(ModalLive);