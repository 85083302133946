import PageHeader from "app/components/PageHeader";
import PageWrapper from "app/components/PageWrapper";
import React, { FC } from "react";

export const BaseLayout: FC = ({
    children
}) => {
    return (
        <React.Fragment>
            <PageHeader />
            <PageWrapper>
                {children}
            </PageWrapper>
        </React.Fragment>
    )
}