import { Box, Typography } from "app/components";
import Button from "app/components/Button";
import { PasswordInput } from "app/components/Inputs";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  ChangePasswordFormSchema,
  changePasswordFormSchema,
} from "./model/changePasswordFormSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import PasswordChecklist from "react-password-checklist";
import React, { useState } from "react";
import { useAppDispatch } from "store";
import { changePasswordThunk } from "./model/changePassword";
import { NotifyError, NotifySuccess } from "app/components/Toastify/Toastify";
import {
  LoadingIndicator,
  LoadingWrapper,
} from "app/components/LoadingIndicator";

type ChangePasswordModalProps = {
  modalDialog?: boolean;
  modalTitle?: string;
  onClose: () => void;
  onOk?: () => void;
  onSubmit?: (values: ChangePasswordFormSchema) => void;
};

export function ChangePasswordModal({
  modalDialog,
  onClose,
  onSubmit,
  onOk,
  modalTitle,
}: ChangePasswordModalProps) {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const methods = useForm<ChangePasswordFormSchema>({
    resolver: zodResolver(changePasswordFormSchema),
  });

  const {
    handleSubmit,
    setValue,
    register,
    control,
    formState: { errors },
  } = methods;

  const userNewPassword = useWatch({
    control,
    name: "newPassword",
  });

  const userValidPassword = useWatch({
    control,
    name: "validPassword",
  });

  const userConfirmPassword = useWatch({
    control,
    name: "confirmPassword",
  });

  const handleValidNewPassword = (isValid: boolean) => {
    setValue("validPassword", isValid);
  };

  const handleCloseDialog = () => {
    onClose?.();
  };

  const onSubmitHandler = async (formValues: ChangePasswordFormSchema) => {
    setIsLoading(true);

    const resultAction = await dispatch(changePasswordThunk(formValues));

    if (changePasswordThunk.fulfilled.match(resultAction)) {
      NotifySuccess("Пароль был успешно изменен!");
      onOk?.();
      onClose?.();
    } else {
      const payloadData = resultAction.payload;
      let errorMessage =
        payloadData?.data?.message || "Ошибка. Неверный логин или пароль";
      if (payloadData?.data?.detail) {
        errorMessage = payloadData?.data?.detail;
      }
      NotifyError(errorMessage);
    }
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      {isLoading && (
        <LoadingWrapper>
          <LoadingIndicator />
        </LoadingWrapper>
      )}
      <Modal size="md" centered isOpen={modalDialog} toggle={handleCloseDialog}>
        <ModalHeader toggle={handleCloseDialog}>
          {modalTitle ?? "Сбросить пароль"}
        </ModalHeader>
        <ModalBody>
          <FormProvider {...methods}>
            <form noValidate autoComplete="off">
              <Box as="span">
                <input
                  autoComplete="false"
                  name="hidden"
                  type="text"
                  style={{ display: "none" }}
                />
                <PasswordInput
                  type="password"
                  placeholder="Введите пароль"
                  label="Текущий пароль:"
                  {...register("oldPassword", {
                    required: true,
                    maxLength: 80,
                  })}
                />
                <Box sx={{ marginTop: "12px" }}>
                  <PasswordInput
                    type="password"
                    placeholder="Введите пароль"
                    label="Новый пароль:"
                    {...register("newPassword", {
                      required: true,
                      maxLength: 80,
                    })}
                  />
                </Box>
                <Box sx={{ marginTop: "12px" }}>
                  <PasswordInput
                    type="password"
                    placeholder="Повторите пароль"
                    label="Повторите пароль:"
                    invalid={!!errors.confirmPassword}
                    errorText={errors.confirmPassword?.message}
                    {...register("confirmPassword", {
                      required: true,
                      maxLength: 80,
                    })}
                  />
                </Box>
              </Box>
            </form>
          </FormProvider>

          <Box sx={{ marginTop: "16px", fontSize: "14px" }}>
            <Typography>
              Пароль недолжен совпадать с 5 (пятью) предыдущими паролями.
            </Typography>
            <Typography>Пароль должен быть не менее 8-ми символов.</Typography>
            <Typography>
              Пароль должен содержать символы по крайней мере из трех
              приведенных групп:
            </Typography>
            {userNewPassword && (
              <Box sx={{ marginTop: "12px" }}>
                <Typography color="red">Требования к паролю:</Typography>
                <PasswordChecklist
                  rules={[
                    "minLength",
                    "letter",
                    "number",
                    "capital",
                    "specialChar",
                    "match",
                  ]}
                  minLength={8}
                  onChange={handleValidNewPassword}
                  value={userNewPassword}
                  valueAgain={userConfirmPassword}
                  messages={{
                    minLength: "8 и более символов",
                    letter: "буквы латинского алфавита в нижнем регистре (a-z)",
                    number: "цифры (0-9)",
                    capital:
                      "буквы латинского алфавита в верхнем регистре (A-Z)",
                    specialChar: "специальные символы и знаки пунктуации",
                    match: "Пароли не совпадают",
                  }}
                />
              </Box>
            )}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={!userValidPassword}
            className="me-2"
            color={!userValidPassword ? "secondary" : "primary"}
            onClick={handleSubmit(onSubmit || onSubmitHandler)}
          >
            Сохранить
          </Button>
          <Button className="me-2" onClick={handleCloseDialog} color="warning">
            Закрыть
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
