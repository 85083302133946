import { ReactComponent as CameraFillIcon } from "assets/icons/svg/camera-fill.svg";
import Button from "app/components/Button";
import { memo } from "react";
import { ButtonProps } from "reactstrap";

const CameraBtn = (props: ButtonProps) => {
    return (
        <Button size="sm" color="primary" {...props}>
            <CameraFillIcon />
        </Button>
    )
}

export default memo(CameraBtn);