import { createApi } from "@reduxjs/toolkit/query/react";
import { DiscrepanciesDTO, RequestDiscrepancyWagon, WagonCorrectionDto } from "store/types";
import axiosBaseQuery from "utils/request";
import { WagonDTO, WagonFilterDTO, WagonPhotoDTO, WagonWrapper } from "./types";

export const wagonApi = createApi({
  reducerPath: "wagonApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api",
  }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getWagonById: builder.query<WagonDTO, string | void>({
      query: (wagonId) => ({
        url: `/wagon/${wagonId}`,
        method: "GET",
      }),
    }),
    getWagons: builder.query<WagonWrapper, string | void>({
      query: (trainId) => ({
        url: `/wagons/${trainId}`,
        method: "GET",
      }),
    }),
    getWagonsWithFilter: builder.query<WagonWrapper, WagonFilterDTO>({
      query: ({
        trainId,
        params
      }) => ({
        url: `/wagons/${trainId}`,
        method: "GET",
        params: params
      }),
    }),
    getDiscrepancies: builder.query<
      DiscrepanciesDTO,
      {
        trainId: string;
        wagonId: string;
      }
    >({
      query: ({ trainId, wagonId }) => ({
        url: `/wagons/discrepancies?train_id=${trainId}&wagon_id=${wagonId}`,
        method: "GET",
      }),
    }),
    wagonApprove: builder.mutation<
      void,
      {
        discrepancy: RequestDiscrepancyWagon;
        trainId: string;
        wagonId: string;
      }
    >({
      query: ({ trainId, wagonId, discrepancy }) => ({
        url: `/wagons/discrepancies/?train_id=${trainId}&wagon_id=${wagonId}`,
        method: "POST",
        data: discrepancy,
      }),
    }),
    wagonCorrect: builder.mutation<void,
      {
        trainId: string;
        wagonId: string;
        number: string;
        userName: string;
      }
    >({
      query: ({ trainId, wagonId, number, userName }) => ({
        url: `/wagon/correct/?train_id=${trainId}&wagon_id=${wagonId}`,
        method: "PUT",
        data: {
          number, userName
        },
      }),
    }),
    wagonCorrections: builder.query<
      WagonCorrectionDto[],
      {
        wagonId: string;
      }
    >({
      query: ({ wagonId }) => ({
        url: `/wagon/get-changes-list/?wagon_id=${wagonId}`,
        method: "GET",
      }),
    }),
    getPhotos: builder.query<
      WagonPhotoDTO,
      {
        trainId: string;
        wagonId: string;
      }
    >({
      query: ({ trainId, wagonId }) => ({
        url: `/wagons/photos?train_id=${trainId}&wagon_id=${wagonId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetWagonsWithFilterQuery,
  useGetWagonsQuery,
  useGetDiscrepanciesQuery,
  useWagonApproveMutation,
  useGetPhotosQuery,
  useGetWagonByIdQuery,
  useWagonCorrectMutation,
  useWagonCorrectionsQuery
} = wagonApi;
