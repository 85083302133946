import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Link = styled(NavLink)`
  text-decoration: none;
  color: inherit;
  display: block;

  &:active {
    background-color: inherit;
  }
`;

export default Link;
