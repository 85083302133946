import { CSSProperties } from "react";
import styled from "styled-components"

const BoxContainer = styled.div({
    position: 'relative',
});

type BoxProps = {
    sx?: CSSProperties,
} & React.ComponentProps<typeof BoxContainer>

export function Box({ sx = {}, ...props }: BoxProps) {
    return (
        <BoxContainer style={{ ...sx }} {...props} />
    )
}