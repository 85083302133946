import { createGlobalStyle } from "styled-components";
import FontRobotoMedium from "assets/fonts/Roboto/Roboto-Medium.ttf";
import FontRobotoRegular from "assets/fonts/Roboto/Roboto-Regular.ttf";
import FontRobotoLight from "assets/fonts/Roboto/Roboto-Light.ttf";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Roboto;
  }

  @font-face {
      font-family: 'Roboto';
      src: url(${FontRobotoLight}) format("opentype");
      font-weight: 300;
  }


  @font-face {
    font-family: 'Roboto';
    src: url(${FontRobotoRegular}) format("opentype");
    font-weight: 400;
  }

  @font-face {
    font-family: 'Roboto';
    src: url(${FontRobotoMedium}) format("opentype");
    font-weight: 500;
  }

  @font-face {
    font-family: 'Roboto';
    src: url(${FontRobotoMedium}) format("opentype");
    font-weight: 600;
  }
`;

export default GlobalStyle;
