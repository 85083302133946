import LangSwitcher from "./LangSwitcher";
import { Wrapper } from "./styles";
import UserPanel from "./UserPanel";

const NavMenu = () => {
  return (
    <Wrapper>
      <LangSwitcher />
      <UserPanel />
    </Wrapper>
  );
};

export default NavMenu;
