import { FC } from "react";
import { ButtonProps } from "reactstrap";
import { ButtonContainer } from "./styles";

type IconButtonProps = {
  children?: React.ReactNode;
  size?: string;
  outline?: boolean;
  disabled?: boolean;
} & ButtonProps;

export const IconButton: FC<IconButtonProps> = ({ children, size, outline, disabled, ...rest }) => {
  return (
    <ButtonContainer
      size={size}
      outline={outline}
      disabled={disabled}
      {...rest}
    >
      {children}
    </ButtonContainer>
  );
};

IconButton.defaultProps = {
  color: "primary",
};
