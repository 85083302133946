import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { checkUser } from "store/auth/reducer";
import { UserResponse } from "store/auth/types";
import { currentUser } from "store/users/actions";

const RequireUser = ({ allowedRoles }: { allowedRoles: string[] }) => {
  const dispatch = useAppDispatch();
  const [renderChild, setRenderChild] = useState<boolean>(false);
  const { success, userInfo } = useAppSelector((state) => state.auth);
  const logged_in = success;

  const location = useLocation();

  useEffect(() => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      const parsedData = JSON.parse(userInfo) as UserResponse;
      dispatch(checkUser(parsedData));
      dispatch(currentUser());
    } else {
      setRenderChild(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (success) {
      setRenderChild(true);
    }
  }, [success]);

  return renderChild ? (
    logged_in && allowedRoles.includes(userInfo?.mainRole || "") ? (
      <Outlet />
    ) : logged_in ? (
      <Navigate to="/403" state={{ from: location }} replace />
    ) : (
      <Navigate to="/login" state={{ from: location }} replace />
    )
  ) : (
    <></>
  );
};

export default RequireUser;
