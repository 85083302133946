import { ReactNode, CSSProperties } from 'react'

import * as S from './styled'

type TagVariants = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span'

type TypographyProps = {
  tag?: TagVariants;
  children: ReactNode;
  sx?: CSSProperties;
}

export const Typography = ({ tag = 'p', children, sx }: TypographyProps 
& CSSProperties) => (
  <S.DynamicTypography tag={tag} style={{ ...sx }}>
    {children}
  </S.DynamicTypography>
);