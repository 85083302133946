import { Button } from "reactstrap";
import styled from "styled-components/macro";

export const ButtonContainer = styled(Button)`
  padding: 8px 14px;
  font-size: 14px;
  font-weight: 500;
  outline: none;

  &.btn-primary:not(:disabled) {
    background-color: #0094e0;
    border-color: transparent;
  }

`;
