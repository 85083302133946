import React, { forwardRef } from "react";
import { Input, Label } from "reactstrap";
import { CheckBoxWrapper } from "./styles";
import { Props } from "./types";

type CheckboxProps = Props & React.InputHTMLAttributes<HTMLInputElement>;

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, name, onChange, onBlur, ...rest }, ref) => {
    const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(evt);
    };

    const handleBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
      onBlur?.(evt);
    };

    return (
      <CheckBoxWrapper>
        <Input
          {...rest}
          innerRef={ref}
          name={name}
          type="checkbox"
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Label check>{label}</Label>
      </CheckBoxWrapper>
    );
  }
);

export default Checkbox;
