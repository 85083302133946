import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "store";
import { authApi } from "store/auth/api";
import { selectUserToken } from "store/auth/reducer";
import { UserChangePasswordRequest } from "store/auth/types";
import { isFetchBaseQueryError } from "store/isFetchBaseQueryError";

export const changePasswordThunk = createAsyncThunk<
  void,
  UserChangePasswordRequest,
  {
    state: RootState;
    rejectValue: ResponseError;
  }
>(
  "auth/changePassword",
  async (body, { dispatch, rejectWithValue, getState }) => {
    try {
      const userToken = selectUserToken(getState());
      if (userToken) {
        await dispatch(
          authApi.endpoints.changePassword.initiate({
            userToken,
            ...body,
          })
        ).unwrap();
      }
    } catch (error: unknown) {
      if (!isFetchBaseQueryError<ResponseError>(error)) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);
