import { createApi } from "@reduxjs/toolkit/query/react";
import { ListRequest, ListResponse } from "store/types";
import axiosBaseQuery from "utils/request";
import { BioTrainDTO, BioWagonPhotoDTO } from "./types";

export const biometryTrainApi = createApi({
  reducerPath: "biometryTrainApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api",
  }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getTrains: builder.query<ListResponse<BioTrainDTO>, ListRequest>({
      query: (args) => ({
        url: "/biometric-info/page",
        method: "GET",
        params: args,
      }),
    }),
    getPhotos: builder.query<
      BioWagonPhotoDTO,
      {
        trainId: string;
        wagonId: string;
      }
    >({
      query: ({ trainId, wagonId }) => ({
        url: `/biometric-info/wagons/photos?train_guid=${trainId}&w_id=${wagonId}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetTrainsQuery, useGetPhotosQuery } =
  biometryTrainApi;
