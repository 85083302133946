import styled from "styled-components/macro";

export const Wrapper = styled.div`
  width: 100%;
  height: 60px;
  background-color: #0497ca;
  display: flex;
  padding: 0 30px;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 1;
`;

export const LogoWrapper = styled.div`
  margin:  auto 0;
  cursor: pointer;
  flex: 1;
`;
