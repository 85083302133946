import React, { ChangeEvent, useCallback, useState } from "react";
import { Input, InputGroupTextProps, Label } from "reactstrap";
import { Wrapper } from "./styles";
import { Props } from "./types";

type InputProps = Props & InputGroupTextProps;

const NumberInput = React.forwardRef<HTMLInputElement, InputProps>(
    ({ label, name, defaultValue = '', ...rest }, ref) => {
        const [inputValue, setInputValue] = useState(defaultValue);

        const onChange = useCallback((eventTarget: ChangeEvent<HTMLInputElement>) => {
            setInputValue(eventTarget.target.value.replace(/\D/g, '')
                .trim());
        }, [])

        return (
            <Wrapper>
                {label && <Label for={name}>{label}</Label>}
                <Input
                    {...rest}
                    onChange={onChange}
                    innerRef={ref}
                    name={name}
                    value={inputValue}
                />
            </Wrapper>
        );
    }
);

export default NumberInput;
