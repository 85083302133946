import { EyeHideIco, EyeIco } from "assets/icons";
import React, { useState } from "react";
import { FormFeedback, Input, InputGroup, Label } from "reactstrap";
import { IconButton } from "../IconButton/IconButton";
import { Wrapper } from "./styles";
import { Props } from "./types";

type InputProps = Props &
  React.InputHTMLAttributes<HTMLInputElement> & {
    innerRef?: React.Ref<HTMLInputElement | HTMLTextAreaElement>;
    bsSize?: "lg" | "sm";
    valid?: boolean;
    invalid?: boolean;
    errorText?: string;
  };

const PasswordInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ label, name, invalid, defaultValue = "", errorText, ...rest }, ref) => {
    const [visiblePassword, setVisiblePassword] = useState<boolean>(false);

    const handleChangeVisible = () => {
      setVisiblePassword((prev) => !prev);
    };

    return (
      <Wrapper>
        {label && <Label for={name}>{label}</Label>}
        <InputGroup>
          <Input
            {...rest}
            invalid={invalid}
            innerRef={ref}
            name={name}
            type={visiblePassword ? "text" : "password"}
            autoComplete="off"
          />
          <IconButton tabIndex={-1} onClick={handleChangeVisible}>
            {!visiblePassword ? <EyeHideIco /> : <EyeIco />}
          </IconButton>
        </InputGroup>
        {invalid && (
          <FormFeedback className="d-block">{errorText}</FormFeedback>
        )}
      </Wrapper>
    );
  }
);

export default PasswordInput;
