import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "store";
import { Links, Wrapper } from "./styles";

const NavMenu = () => {
  const { t } = useTranslation();
  const { userInfo } = useAppSelector((state) => state.auth);

  const roleAccess = {
    trains: ["ADMIN", "OPERATOR", "USER"].includes(userInfo?.mainRole || ""),
    journal: ["ADMIN", "OPERATOR", "USER"].includes(userInfo?.mainRole || ""),
    users: ["ADMIN"].includes(userInfo?.mainRole || ""),
    biometry: ["ADMIN", "BIOMETRIC"].includes(userInfo?.mainRole || ""),
  };

  return (
    <Wrapper>
      <Links>
        {roleAccess.trains && (
          <NavLink to="/trains">{t("main.headers.navigation.trains")}</NavLink>
        )}
        {roleAccess.users && (
          <NavLink to="/users" end>{t("main.headers.navigation.users")}</NavLink>
        )}
        {roleAccess.biometry && <NavLink to="/biometry">Биометрия</NavLink>}
        {roleAccess.users && <NavLink to="/users/logs" end>Журнал событий</NavLink>}
      </Links>
    </Wrapper>
  );
};

export default NavMenu;
