import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { currentUser } from "./actions";
import { UserResponse, RequestState } from "./types";

const initialState: RequestState  = {
  isLoading: false,
  error: "",
  userData: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: { 
  },
  extraReducers: {
    [currentUser.fulfilled.type]: (
      state,
      action: PayloadAction<UserResponse>
    ) => {
      state.isLoading = false;
      state.userData = action.payload;
    },
    [currentUser.pending.type]: (state) => {
      state.isLoading = true;
    },
    [currentUser.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
}); 

export default userSlice.reducer;
