import GlobalStyle from "./theme/globalStyles";

import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from "react-toastify";

import "react-image-lightbox/style.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { appRouter } from "./appRouter";

import {
  LoadingIndicator,
  LoadingWrapper,
} from "app/components/LoadingIndicator";

function App() {
  return (
    <>
      <GlobalStyle />
      <ToastContainer />
      <RouterProvider
        router={appRouter()}
        fallbackElement={
          <LoadingWrapper>
            <LoadingIndicator />
          </LoadingWrapper>
        }
      />
      {/* <Routes>
        <Route
          element={
            <RequireUser
              allowedRoles={["ADMIN", "OPERATOR", "USER", "BIOMETRIC"]}
            />
          }
        >
          <Route path="/" element={<Navigate to="/trains" replace />} />
        </Route>
        <Route
          element={<RequireUser allowedRoles={["ADMIN", "OPERATOR", "USER"]} />}
        >
          <Route path="trains" element={<Outlet />}>
            <Route index element={<TrainPage />} />
            <Route path=":id" element={<TrainDetailPage />} />
            <Route path="car/:id/:wagonId" element={<TrainCarPage />} />
          </Route>
          <Route path="journal" element={<JournalPage />} />
          <Route path="profile" element={<ProfilePage />} />
        </Route>
        <Route element={<RequireUser allowedRoles={["ADMIN", "BIOMETRIC"]} />}>
          <Route path="biometry" element={<BiometryPage />}>
            <Route index element={<BiometryTrainPage />} />
            <Route path="wagons/:trainId" element={<BiometryWagonPage />} />
            <Route
              path="wagons/photos/:trainId/:wagonId"
              element={<BiometryWagonPhotosPage />}
            />
          </Route>
        </Route>
        <Route element={<RequireUser allowedRoles={["ADMIN"]} />}>
          <Route path="users/*" element={<UserPage />} />
        </Route>

        <Route path="login" element={<LoginPage />} />
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="404" element={<NotFoundPage />} />
        <Route path="403" element={<NotPermissionPage />} />
        <Route path="401" element={<NotAuthPage />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes> */}
    </>
  );
}

export default App;
