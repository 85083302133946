import styled from "styled-components/macro";
import RDatePicker, { ReactDatePickerProps } from "react-datepicker";
import { FC, useEffect, useMemo, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import ruLocale from "date-fns/locale/ru";
import kkLocale from "date-fns/locale/kk";

import { ReactComponent as CalendarIco } from "assets/icons/svg/datepicker-calendar.svg";
import { useTranslation } from "react-i18next";
import { dateFormat, dateTimeFormat, formatDateString } from "utils/dateHelper";

export type Nullable<T> = T | null;

type RangeNullDate = [Nullable<Date>, Nullable<Date>];

type PickerProps = {
  label: string;
  maxDate?: Nullable<Date> | undefined;
  minDate?: Nullable<Date> | undefined;
  onChange?: (date: Nullable<Date>, evt: any) => void;
} & ReactDatePickerProps;

const DatePicker: FC<PickerProps> = ({
  label,
  name,
  maxDate,
  minDate,
  onChange,
  showTimeSelect,
  ...rest
}) => {
  const { i18n } = useTranslation();

  const currentLang = useMemo(() => {
    return i18n.language === "kk" ? kkLocale : ruLocale;
  }, [i18n.language]);

  return (
    <Wrapper className={rest.className}>
      {label && <Label>{label}</Label>}
      <InputContainer>
        <RDatePicker
          locale={currentLang}
          calendarStartDay={1}
          dateFormat={showTimeSelect ? dateTimeFormat : dateFormat}
          showTimeSelect={showTimeSelect}
          placeholderText="Выберите дату"
          maxDate={maxDate}
          minDate={minDate}
          onChange={onChange}
          {...rest}
        />
        <IconContainer>
          <CalendarIco className="calendar-chevron-ico" />
        </IconContainer>
      </InputContainer>
    </Wrapper>
  );
};

type RangeProps = {
  labels: string[];
  values?: RangeNullDate;
  onChange?: (date: RangeNullDate, dateString: string[]) => void;
} & Pick<
  ReactDatePickerProps,
  "showTimeSelect" | "timeFormat" | "timeCaption" | "timeIntervals"
>;

export const RangePicker: FC<RangeProps> = ({
  values = [null, null],
  labels = ["", ""],
  onChange,
  showTimeSelect,
  ...rest
}) => {
  const [dateValues, setDateValues] = useState<RangeNullDate>(values);
  const [fromDate, toDate] = dateValues;
  const fromLabel = labels[0];
  const toLabel = labels[1];

  const onChangeDate = (index: number) => (date: Date | null) => {
    const dates: RangeNullDate = [...dateValues];
    dates[index] = date;
    setDateValues(dates);
    const checkDates = dates.every((item) => item !== null);
    if (checkDates) {
      onChange?.(dates, [
        formatDateString(
          dates[0],
          showTimeSelect ? dateTimeFormat : dateFormat
        ),
        formatDateString(
          dates[1],
          dateTimeFormat ? dateTimeFormat : dateFormat
        ),
      ]);
    }
  };

  useEffect(() => {
    if (values) {
      setDateValues(values);
    }
  }, [values]);

  return (
    <>
      <DatePicker
        onChange={onChangeDate(0)}
        label={fromLabel}
        selected={fromDate}
        maxDate={toDate || new Date()}
        showTimeSelect={showTimeSelect}
        {...rest}
      />
      <DatePicker
        onChange={onChangeDate(1)}
        highlightDates={fromDate ? [fromDate] : undefined}
        label={toLabel}
        selected={toDate}
        minDate={fromDate}
        maxDate={new Date()}
        showTimeSelect={showTimeSelect}
        {...rest}
      />
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
`;

const Label = styled.div`
  font-size: 14px;
`;

const InputContainer = styled.div`
  margin-left: 8px;
  display: flex;
  align-items: center;

  input {
    border: 1px solid transparent;
    background: #f3f6f9;
    border-radius: 4px;
    padding: 8px;
    color: #464e5f;
    font-size: 16px;

    :focus {
      color: #0497ca;
    }
  }
`;

const IconContainer = styled.div`
  position: relative;

  .calendar-chevron-ico {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 6px;

    &:hover {
      path {
        fill: #0497ca !important;
      }
    }
  }
`;

export default DatePicker;
