import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { axiosInstance } from "utils/request";

export const currentUser = createAsyncThunk(
  "user/me",
  async (_, thunkApi) => {
    try {
      const response = await axiosInstance.get<AxiosResponse>(
        "/api/user/current"
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
