import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "utils/request";
import {
  LoginRequest,
  UserChangePasswordRequest,
  UserResetPasswordRequest,
  UserResponse,
} from "./types";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    login: builder.mutation<UserResponse, LoginRequest>({
      query: (credentials) => ({
        url: "/api/auth/sign-in",
        method: "POST",
        data: credentials,
      }),
    }),

    resetPassword: builder.mutation<void, UserResetPasswordRequest>({
      query: (data) => {
        return {
          url: `/api/user/reset-pass`,
          method: "POST",
          data,
        };
      },
    }),

    changePassword: builder.mutation<void, UserChangePasswordRequest & {
      userToken: string
    }>({
      query: ({ userToken ,...data}) => {
        return {
          url: `/api/user/change-pass`,
          method: "POST",
          data,
          headers: {
            Authorization: `Bearer ${userToken}`
          },
        };
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
} = authApi;
