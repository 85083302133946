import { RouteObject } from "react-router-dom";

export const usersRoutes: RouteObject[] = [
  {
    index: true,
    async lazy() {
      const { UsersListPage } = await import("./UsersList");

      return {
        element: <UsersListPage />,
      };
    },
  },
  {
    path: "edit/:id",
    async lazy() {
      const { UsersEditPage } = await import("./UsersEdit");

      return {
        element: <UsersEditPage />,
      };
    },
  },
  {
    path: "add",
    async lazy() {
      const { UsersAddPage } = await import("./UsersAdd");

      return {
        element: <UsersAddPage />,
      };
    },
  },
  {
    path: "logs",
    async lazy() {
      const { UsersLogs } = await import("./UsersLogs");

      return {
        element: <UsersLogs />,
      };
    },
  },
];
