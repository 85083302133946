import { format as dateFormatter, isValid, parse } from "date-fns";

export const dateFormat = "dd.MM.yyyy";

export const dateFormatDefault = "yyyy-MM-dd";

export const dateFormatDelimeter = "dd-MM-yyyy";

export const dateTimeFormat = `${dateFormat} HH:mm:ss`;

export const dateTimeFormatDelimeter = `${dateFormatDelimeter} HH:mm:ss`;

export const getCurrentDateString = (): string => {
  return dateFormatter(new Date(), dateFormat);
};

export const getDateTimeString = (): string => {
  return dateFormatter(new Date(), dateTimeFormat);
};

export const formatDateString = (
  value: Date | null | undefined,
  outputFormat: string | undefined = dateFormat
): string => {
  if (value) {
    return dateFormatter(value, outputFormat);
  }
  return "";
};

export const formatDateTimeString = (
  value: Date | null | undefined,
  valid: boolean = false
): string => {
  if (valid && !isValid(value)) {
    return "";
  }
  if (value) {
    return dateFormatter(value, dateTimeFormat);
  }
  return "";
};

export const stringToDate = (
  value: string | null | undefined,
  outputFormat: string | undefined = dateFormat
): Date | null => {
  if (value) {
    return parse(value, outputFormat, new Date());
  }
  return null;
};

export const stringToDateTime = (
  value: string | null | undefined
): Date | null => {
  if (value) {
    return parse(value, dateTimeFormat, new Date());
  }
  return null;
};