import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";
import { BioWagonDTO } from "store/biometryWagon/types";
import { ListRequest } from "store/types";
import { biometryWagonApi } from "./api";
import { ResponseState } from "./types";

const defaultTrainsFilter: ListRequest = {
  filterId: nanoid(),
  filter: null,
  number: 0,
  size: 15,
  sortField: "w_order",
  sort: "asc",
  from: null,
  to: null,
};

const initialState: ResponseState = {
  wagons: {
    filter: defaultTrainsFilter,
    result: [],
  },
};

export const biometryWagonSlice = createSlice({
  name: "biometryWagon",
  initialState,
  reducers: {
    changeFilter: (state, action: PayloadAction<ListRequest>) => {
      state.wagons.filter = action.payload;
    },
    resetFilter: (state) => {
      state.wagons.filter = defaultTrainsFilter;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      biometryWagonApi.endpoints.getWagons.matchFulfilled,
      (state, action: PayloadAction<BioWagonDTO[]>) => {
        state.wagons.result = action.payload;
      }
    );
  },
});

export const { changeFilter, resetFilter } = biometryWagonSlice.actions;

export default biometryWagonSlice.reducer;
